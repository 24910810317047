import ApiInstance from './ApiInstance.js'

export default {
  
  getDegreeLevels() {
    return ApiInstance({ requiresAuth: true }).get('/degree-level');
  },
  addDegreeLevel(Data) {
    return ApiInstance({ requiresAuth: true }).post('/degree-level', Data);
  },
  editDegreeLevel(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/degree-level/${id}`, Data);
  },
  deleteDegreeLevel(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/degree-level/${id}`);
  },

  // Degree Subjects
  getDegreeSubjects() {
    return ApiInstance({ requiresAuth: true }).get('/degree-subject');
  },
  addDegreeSubjects(Data) {
    return ApiInstance({ requiresAuth: true }).post('/degree-subject', Data);
  },
  editDegreeSubjects(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/degree-subject/${id}`, Data);
  },
  deleteDegreeSubject(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/degree-subject/${id}`);
  }
}
